<template>
  <div class="gradient-background background-height">
    <v-container>
      <v-row align="center" justify="center" class="my-12">
        <v-card
          max-width="95vw"
          class="z-index-top animate__animated animate__fadeInLeft animate__faster"
        >
          <v-form v-model="valid">
            <v-sheet class=" fill-height" dark color="primary darken-4">
              <v-card-actions class="pa-4">
                <v-btn rounded color="error" to="login">
                  <v-icon>mdi-arrow-left</v-icon>
                  Volver
                </v-btn>
              </v-card-actions>
              <v-row align="center" justify="center" class="px-12">
                <v-img
                  src="../assets/smartpill_icon.png"
                  class="img-white"
                  max-height="68"
                  max-width="68"
                />
              </v-row>
              <v-row align="center" justify="center" class="px-12">
                <v-card-title class="display-2 ml-n3 ">
                  <span class="primary--text font-weight-bold">
                    Smart
                  </span>
                  PillBox
                </v-card-title>
              </v-row>
            </v-sheet>
            <v-card-text class="pa-12">
              <v-row>
                <v-card-title class="">
                  Registrar una nueva cuenta
                </v-card-title>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="user.firstName"
                    :rules="genericRules"
                    label="Nombre"
                    required
                    outlined
                    rounded
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="user.lastName"
                    :rules="genericRules"
                    label="Apellido"
                    required
                    outlined
                    rounded
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    label="Correo Electrónico"
                    required
                    outlined
                    rounded
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    label="Contraseña"
                    type="password"
                    required
                    outlined
                    rounded
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="passwordConfirmation"
                    :rules="passwordConfirmationRules"
                    label="Confirma tu contraseña"
                    type="password"
                    required
                    outlined
                    rounded
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-list dense>
                    <v-card-subtitle
                      >La contraseña debe tener como mínimo:</v-card-subtitle
                    >
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="hasLarge ? 'success' : 'error'">
                              {{ hasLarge ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span class="caption">Un largo minimo de 8</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="hasMinus ? 'success' : 'error'">
                              {{ hasMinus ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span class="caption">Una letra minúscula</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="hasMayus ? 'success' : 'error'">
                              {{ hasMayus ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span class="caption">Una letra mayúscula</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="hasSpecial ? 'success' : 'error'">
                              {{ hasSpecial ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span class="caption">Un caracter especial</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon :color="hasNumber ? 'success' : 'error'">
                              {{ hasNumber ? "mdi-check" : "mdi-close" }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <span class="caption">Un número</span>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-card-text class="title">Fecha de cumpleaños</v-card-text>
                  <v-date-picker
                    v-model="user.birthday"
                    full-width
                    color="accent"
                    header-color="primary"
                  ></v-date-picker>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  rounded
                  color="primary"
                  dark
                  @click="registro()"
                  :loading="isLoading"
                >
                  Enviar registro
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
    <div>
      <div class="box">
        <div class="wave -one"></div>
        <div class="wave -two"></div>
        <div class="wave -three"></div>
        <div class="title"></div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "../plugins/axios";

export default {
  name: "login",
  data: () => ({
    isLoading: false,
    valid: false,
    passwordConfirmation: "",
    password: "",
    user: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      birthday: "2020-01-01",
    },
    hasMayus: false,
    hasSpecial: false,
    hasNumber: false,
    hasMinus: false,
    hasLarge: false,
    passwordRules: [
      (v) => !!v || "La contraseña es requerida",
      (v) => v.length > 8 || "La contraseña debe tener un largo minimo de 8",
      (v) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v) ||
        "La contraseña debe cumplir con el formato requerido.",
    ],

    email: "",
    genericRules: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) =>
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
          v
        ) || "El correo electrónico debe ser válido",
    ],
  }),
  mounted() {
    let now = dayjs().format("YYYY-MM-DD");
    this.user.birthday = now;
  },
  watch: {
    password: function(v) {
      this.hasMayus = /^(?=.*[A-Z])/.test(v);
      this.hasSpecial = /^(?=.*[!@#$%^&*])/.test(v);
      this.hasNumber = /^(?=.*[0-9])/.test(v);
      this.hasMinus = /^(?=.*[a-z])/.test(v);
      this.hasLarge = /^(?=.{8,})/.test(v);
    },
  },
  computed: {
    passwordConfirmationRules() {
      return [
        (v) => !!v || "La confirmación de la contraseña es requerida",
        (v) => v === this.password || "Las contraseñas no coinciden",
      ];
    },
  },
  methods: {
    registro() {
      this.isLoading = true;
      this.user.password = this.password;
      this.user.email = this.user.email.toLowerCase();
      axios
        .post("auth/register", this.user)
        .then((response) => {
          let serviceResponse = response.data;
          if (serviceResponse) {
            this.$toast.success(serviceResponse.message);
            this.$router.push("/login");
          }
          this.isLoading = false;
        })
        .catch((e) => {
          if (e) {
            this.$toast.error(
              "Ha ocurrido un error, intente nuevamente más tarde"
            );
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.gradient-background {
  position: relative;
  background: var(--v-background-base);
}
.z-index-top {
  z-index: 999;
}
.background-height {
  min-height: 90vh;
}
.box {
  margin-top: -50vh;
  width: 100%;
  height: 500px;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: var(--v-background-base);
  position: relative;
  overflow: hidden;
  bottom: 0;
  transform: translate3d(0, 0, 0);
}

.wave {
  opacity: 0.8;
  position: absolute;
  top: 60%;
  left: 30%;
  background: var(--v-primary-base);
  width: 3500px;
  height: 3500px;
  margin-left: -1450px;
  margin-top: -150px;
  transform-origin: 50% 48%;
  border-radius: 63%;
  animation: drift 3000ms infinite linear;
}

.wave.-three {
  animation: drift 5000ms infinite linear;
  margin-left: -1150px;
}

.wave.-two {
  animation: drift 7000ms infinite linear;
  margin-left: -1850px;
}

.box:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
</style>

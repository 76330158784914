<template>
  <div class="background-height">
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center">
        <v-card
          class="z-index-top animate__animated animate__fadeInLeft animate__faster"
          max-width="90vw"
          min-height="50vh"
          elevation="4"
        >
          <v-row align="stretch" justify="center" no-gutters>
            <v-col cols="12" sm="12" md="6">
              <v-sheet class="pa-12 fill-height" dark color="primary darken-4">
                <v-row align="center" justify="center">
                  <v-img
                    src="../assets/smartpill_icon.png"
                    class="img-white"
                    max-height="68"
                    max-width="68"
                  />
                </v-row>
                <v-row align="center" justify="center">
                  <v-card-title class="display-2 ml-n3 ">
                    <span class="primary--text font-weight-bold">
                      Smart
                    </span>
                    PillBox
                  </v-card-title>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn
                  text
                  rounded
                  small
                  color="primary"
                  class="mx-2"
                  @click="toogleDarkMode"
                >
                  <v-icon>mdi-brightness-3</v-icon>
                </v-btn>
              </v-card-actions>
              <v-row align="center" justify="center" class="fill-height">
                <v-form v-model="valid" class="pb-12 px-6 px-md-12">
                  <v-container>
                    <v-card-title class="title justify-center">
                      Iniciar sesión
                    </v-card-title>
                    <v-row align="center" justify="center" no-gutters>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="user.email"
                          :rules="emailRules"
                          label="Correo Electrónico"
                          required
                          outlined
                          rounded
                        />
                      </v-col>

                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="user.password"
                          :rules="passwordRules"
                          label="Contraseña"
                          type="password"
                          required
                          outlined
                          rounded
                        />
                      </v-col>
                    </v-row>
                    <v-card-subtitle class="mt-n6">
                      ¿No tienes una cuenta?,
                      <router-link :to="{ name: 'Registro' }">
                        Crea una
                      </router-link>
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        dark
                        @click="login()"
                        block
                        rounded
                        :loading="isLoading"
                      >
                        Iniciar sesión
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>
    <div>
      <div class="box">
        <div class="wave -one"></div>
        <div class="wave -two"></div>
        <div class="wave -three"></div>
        <div class="title"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  name: "login",
  data: () => ({
    isLoading: false,
    valid: false,
    user: {
      email: "",
      password: "",
    },
    passwordRules: [(v) => !!v || "La contraseña es requerida"],
    email: "",
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /.+@.+/.test(v) || "El correo electrónico debe ser válido",
    ],
  }),
  methods: {
    toogleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },
    login() {
      this.isLoading = true;
      let auth = {
        email: this.user.email.toLowerCase(),
        password: this.user.password,
        dispositive: navigator.userAgent,
      };
      axios
        .post("auth/login", auth)
        .then((response) => {
          let serviceResponse = response.data;
          if (serviceResponse) {
            sessionStorage.setItem("token", serviceResponse.data.token);
            sessionStorage.setItem("userId", serviceResponse.data.userId);
            sessionStorage.setItem("email", serviceResponse.data.email);
            sessionStorage.setItem("username", serviceResponse.data.username);
            this.$toast.success(serviceResponse.message);
            this.$router.push("/inicio");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          if (error) {
            this.$toast.error("E-mail y/o contraseña incorrecta.");
            this.isLoading = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.background-height {
  min-height: 100vh;
}
.z-index-top {
  z-index: 999;
}
.box {
  margin-top: -100vh;
  width: 100%;
  height: 100vh;
  background: var(--v-background-base);
  position: sticky;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.wave {
  opacity: 0.8;
  position: absolute;
  top: 90%;
  left: 80%;
  background: var(--v-primary-base);
  width: 2000px;
  height: 2000px;
  margin-left: -1450px;
  margin-top: -150px;
  transform-origin: 50% 48%;
  border-radius: 63%;
  animation: drift 3s infinite linear;
}

.wave.-three {
  animation: drift 5s infinite linear;
  margin-left: -1150px;
}

.wave.-two {
  animation: drift 7s infinite linear;
  margin-left: -1850px;
}

.box:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--v-background-darken1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--v-background-darken1);
}
</style>
